
let lockedSections = document.querySelectorAll('[hasLock="true"]')

if (lockedSections) {
    for (let section of lockedSections) {
        let toggle = section.querySelector('[formUnlock]')
        let lockState = section.querySelectorAll('[lockState]')
        let deleteButtons = section.querySelectorAll('[lockedDelete]')
        toggle.addEventListener("click", () => {
            for (let button of deleteButtons) {
                button.classList.toggle('opacity-25')
                button.toggleAttribute("disabled")
            }
            for (let state of lockState) {
                state.classList.toggle('d-none')
            }
        })
    }
 }

//  This module lets you lock/unlock the 'delete' button on any database relationship list. To make the list, ensure that the list's nearest all-encompassing parent has the attribute 'hasLock=true'
// 
/* then, use the following code (for example) to create your lock button within:
<div class="col-2 text-end ps-4 pb-2" formUnlock>
    <i class="bi bi-lock-fill text-secondary btn border-0 py-0 ps-4"> lockState></i>
    <i class="bi bi-unlock text-danger btn border-0 py-0 ps-4 d-none" 
    lockState></i>
</div>
*/ 
// then, use the following format for all form elements' 'delete/submit' buttons:
/*
<button type="submit" class="btn opacity-25 dangerHover border-0 py-0" disabled lockedDelete><i class="bi bi-trash3"></i></button>
*/ 
// check out the parentItem and childItem blocks of inventory.detail.ejs for some examples in practice